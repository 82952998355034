import React from 'react';
import { useSelector } from 'react-redux';
import {Card, CardBody, CardHeader} from "reactstrap";
import errorStrings from "../../utility/errorStrings";

const ErrorHandler = props => {

  const errorCode = useSelector(state => state.errorCode);
  const claims = useSelector(state => state.claims);

  const errorTextList1 = {
    [errorStrings.INTERNET_ERROR]: 'Unable to connect to the Election-Monkey servers',
    [errorStrings.FIRESTORE_ERROR]: 'Unable to retrieve data'
  }

  const errorTextList2 = {
    [errorStrings.INTERNET_ERROR]: 'Please check your internet connection.'
  }

  if (errorCode && claims.admin) {
    return (
      <Card>
        <CardHeader className="text-center">
          <h1>Election Monkey</h1>
          <h4>Admin login</h4>
        </CardHeader>
        <CardBody>
          <h4 className="text-center">{errorTextList1[errorCode]}</h4>
          <h5 className="text-center">{errorTextList2[errorCode]}</h5>
        </CardBody>
      </Card>
    )
  } else {
    return props.children
  }

}

export default ErrorHandler;
