import React from 'react';
import './App.css';
import {Card, CardBody, Col, Row} from "reactstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const InvalidRole = () => {
  return (
    <Card>
      <Header />
      <CardBody>
        <Row>
          <Col>
            <h4 className="text-center">Sorry, you need administrator access</h4>
          </Col>
        </Row>
      </CardBody>
      <Footer />
    </Card>
  )
}

export default InvalidRole;
