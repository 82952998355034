import firebase from 'firebase/app';
import 'firebase/firestore';
import as from '../reducer/actionStrings';
import actionCreator from '../reducer/actionCreator';
import errorStrings from "../utility/errorStrings";

const getFirestoreCollectionAndDispatch = (collection, actionString) => (dispatch, getState) => {
  const db = firebase.firestore();
  db.collection(collection)
    .onSnapshot(querySnapshot => {
        let list = [];
        querySnapshot.forEach(user => {
          list.push(user.data());
        })
        dispatch(actionCreator(actionString, {err: false, list}));
      },
      err => {
        dispatch(actionCreator(as.ERROR, {errorCode: errorStrings.FIRESTORE_ERROR}))
      });
}

export default getFirestoreCollectionAndDispatch;
