import pkg from '../../../package.json';
import { CardFooter } from 'reactstrap';
import './Footer.css';

const Footer = () => {
  return (
    <CardFooter>
      <div className="footer">
        {pkg.description}<br/>
        Version {pkg.version}<br/>
        Copyright {pkg.copyright}
      </div>
    </CardFooter>
  )
}

export default Footer;
