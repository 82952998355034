const firebaseConfig = {
  apiKey: "AIzaSyAvreDYSwEEJcZHPMmJ21lJndRqRQwbx28",
  authDomain: "onlineballot.firebaseapp.com",
  databaseURL: "https://onlineballot-default-rtdb.firebaseio.com",
  projectId: "onlineballot",
  storageBucket: "onlineballot.appspot.com",
  messagingSenderId: "182261387984",
  appId: "1:182261387984:web:2f052275641fe6480b04d5",
  measurementId: "G-EHYREW1VTJ"
};

export default firebaseConfig;
