const actionStrings = {
  LOGIN_SUCCESSFUL: 'loginSuccessful',
  INVALID_LOGIN_FLOW: 'invalidLoginFlow',

  SET_USER_LIST: 'setUserList',
  USER_SELECTED: 'userSelected',
  CLEAR_USER_SELECTED: 'clearUserSelected',
  SET_ELECTION_LIST: 'setElectionList',
  ERROR: 'programError',
  SET_CLAIMS: 'setClaims',
  SET_ID_TOKEN: 'setIdToken'
}

export default actionStrings;
