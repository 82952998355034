import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Header from '../Header/Header';
import UserList from './UserList';
import EditUser from './EditUser';
import Footer from '../Footer/Footer';
import './Users.css';

const Users = () => {

  return (
    <Card>
      <Header />
      <CardBody>
        <Row>
          <Col md={4}>
            <UserList />
          </Col>
          <Col md={8}>
            <EditUser />
          </Col>
        </Row>
      </CardBody>
      <Footer />
    </Card>
  )
}

export default Users;
