const initialState = {
  claims: null,
  idToken: null,
  userList: [],
  userKey: null,
  electionList: [],
  errorCode: null
}

export default initialState;
