import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Form, Table,
  FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import produce from 'immer';
import { fromUnixTime, getUnixTime, format, addMonths, addYears, addWeeks } from 'date-fns';
import './Users.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import constants from '../../utility/constants';
import actionCreator from "../../reducer/actionCreator";
import as from "../../reducer/actionStrings";
import apiCall from "../../utility/apiCall";

const EditUser = () => {

  const [showUserOrElection, setShowUserOrElection] = useState('user');
  const [user, setUser] = useState(null);
  const [elections, setElections] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [expiry, setExpiry] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [db] = useState(firebase.firestore());
  const dispatch = useDispatch();

  const userKey = useSelector(state => state.userKey);
  const idToken = useSelector(state => state.idToken);
  const userList = useSelector(state => state.userList);
  const electionList = useSelector(state => state.electionList);

  useEffect(() => {
    setIsDirty(false);
  }, [userKey]);

  useEffect(() => {
    let u = userList.find(user => user.uid === userKey);
    setUser(u);
  }, [userKey, userList]);

  useEffect(() => {
    let e = electionList.filter(election => election.registrarId === userKey);
    setElections(e);
  }, [userKey, electionList])

  useEffect(() => {
    if (!user) return;
    setAdmin(user.admin);
    setExpiry(user.expiry);
  }, [user]);

  const switchInfoPage = evt => {
    setShowUserOrElection(showUserOrElection === 'user' ? 'election' : 'user');
  };

  const onChangeAdmin = evt => {
    setAdmin(!admin);
    setIsDirty(true);
  }

  const onChangeDate = evt => {
    let dateText = evt.currentTarget.value;
    let y = dateText.substr(0, 4);
    let m = dateText.substr(5, 2);
    let d = dateText.substr(8, 2);
    let newDate = new Date(m+'/'+d+'/'+y);
    setExpiry(getUnixTime(new Date(newDate)));
    setIsDirty(true);
  }

  const addTime = time => () => {
    let exp = fromUnixTime(expiry);
    let nd = null;
    switch (time) {
      case 'week':
        nd = addWeeks(exp, 1);
        break;
      case 'month':
        nd = addMonths(exp, 1);
        break;
      case 'year':
        nd = addYears(exp, 1);
        break;
      default:
        break;
    }
    setExpiry(getUnixTime(nd));
    setIsDirty(true);
  }

  const deleteThisUser = () => {
    setConfirmModal(true)
  }

  const cancelUserDelete = () => {
    setConfirmModal(false);
  }

  const confirmUserDelete = () => {
    setConfirmModal(false);
    db.collection(constants.USER_LIST_KEY)
      .doc(user.uid)
      .delete()
      .then(() => {
        dispatch(actionCreator(as.CLEAR_USER_SELECTED));
      });
    apiCall('deleteUser', idToken, {uid: user.uid})
      .then(response => {
        console.log('user deleted from Firebase');
      })
      .catch(error => {
        console.log(error);
      })
  }

  const disableAccount = () => {
    let now = getUnixTime(new Date()) - 24*60*60;
    let $user = produce(user, draft => {
      draft.expiry = now;
      return draft;
    })
    saveUser($user);
  }

  const saveUser = user => {
    db.collection(constants.USER_LIST_KEY)
      .doc(user.uid)
      .set(user)
      .then(() => {
        setIsDirty(false)
      });
  }

  const save = () => {
    let $user = produce(user, draft => {
      draft.admin = admin;
      draft.expiry = expiry;
      return draft;
    })
    saveUser($user);
  }

  const cancel = () => {
    setExpiry(user.expiry);
    setAdmin(user.admin);
    setIsDirty(false);
  }

  let timeText = user ? format(fromUnixTime(expiry), 'yyyy-MM-dd') : '';

  const renderUser = user => {
    if (!user) return null;
    return (
      <Form>
        <FormGroup row>
          <Label for="role" md={3}>Administrator:</Label>
          <Col md={9}>
            <Input type="checkbox" name="admin" id="admin" className="admin-checkbox" onChange={onChangeAdmin} checked={admin} />
          </Col>
        </FormGroup>
        {
          admin ? null :
            <>
              <FormGroup row>
                <Label for="expiry" md={3}>Expiration:</Label>
                <Col md={9}>
                  <Input type="date" name="expiry" id="expiry" onChange={onChangeDate} value={timeText} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={3}>&nbsp;</Col>
                <Col md={6}>
                  <Button onClick={addTime('week')} color="primary button-spacer">Add week</Button>
                  <Button onClick={addTime('month')} color="primary button-spacer">Add month</Button>
                  <Button onClick={addTime('year')} color="primary button-spacer">Add year</Button>
                </Col>
                <Col md={3} className="text-right">
                  <Button onClick={disableAccount} color="primary">Disable account</Button>
                </Col>
              </FormGroup>
            </>
        }
      </Form>
    )
  }

  const dateToText = date => {
    return format(fromUnixTime(date), 'EEE MMM dd, yyyy HH:mm');
  }

  const renderElections = elections => {
    if (!elections || elections.length === 0) return <h5 className="text-center">There are no elections for this user</h5>;
    return (
      <Table>
        <thead>
        <tr>
          <th>Name</th>
          <th className="text-center">Open</th>
          <th className="text-center">Close</th>
          <th className="text-center">Voters</th>
        </tr>
        </thead>
        <tbody>
        {
          elections.map(election => {
            let openTime = dateToText(election.open);
            let closeTime = dateToText(election.close);
            return (
              <tr key={election.key}>
                <td>{election.name}</td>
                <td className="text-center">{openTime}</td>
                <td className="text-center">{closeTime}</td>
                <td className="text-center">{election.voterCount}</td>
              </tr>
            )
          })
        }
        </tbody>
      </Table>
    )
  }

  const controlButtons = dirty => {
    return isDirty ?
      <>
        <Button onClick={save} disabled={!isDirty} color="primary" className="control-buttons">Save</Button>
        <Button onClick={cancel} color="primary">Cancel</Button>
      </>
      :
      <Button color="primary" onClick={switchInfoPage}>{showUserOrElection === 'user' ? 'Show elections' : 'Show user details'}</Button>
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md={4}>
            <Button color="primary" onClick={deleteThisUser} disabled={!userKey} className="control-buttons">Delete user</Button>
          </Col>
          <Col md={4}>
            <h4>Edit {user && (user.name.length > 0) ? user.name : 'User'}</h4>
          </Col>
          <Col md={4} className="text-right">
            {controlButtons(isDirty)}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {
          showUserOrElection === 'user' ? renderUser(user) : renderElections(elections)
        }
      </CardBody>
      <ModalConfirm modalFlag={confirmModal}
                    body="Are you sure you want to delete this user?"
                    confirmButtonLabel="Yes"
                    className=''
                    confirmCallback={confirmUserDelete}
                    cancelCallback={cancelUserDelete}
                    title="Confirm delete user" />
    </Card>
  )
}

export default EditUser;
