import React from 'react';
import { useSelector } from 'react-redux';
import Login from '../Login/Login';
import Users from '../Users/Users';
import InvalidRole from './InvalidRole';
import './App.css';

const App = () => {

  const claims = useSelector(state => state.claims);
  if (!claims) {
    return <Login />
  } else if (claims.admin) {
    return <Users />
  } else {
    return <InvalidRole />
  }

}

export default App;
