import actionCreator from "../reducer/actionCreator";
import actionStrings from "../reducer/actionStrings";

const apiCall = async (path, idToken, body, dispatch=null) => {
  const rootUrl = document.location.hostname === ('localhost') ?
    'http://localhost:5001/onlineballot/us-central1' :
    'https://us-central1-onlineballot.cloudfunctions.net';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  body = {...body, idToken}
  return new Promise((resolve, reject) => {
    fetch(rootUrl + '/' + path, {
      method: 'POST',
      body: body ? JSON.stringify(body) : '{}',
      headers
    })
      .then(res => {
        return res.json()
      })
      .then(jsonData => {
        resolve(jsonData);
      })
      .catch(err => {
        if (dispatch) {
          dispatch(actionCreator(actionStrings.ERROR, {errorCode: 'fetch/fail'}));
        }
        reject(err);
      })
  });
}

export default apiCall;
