import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { format, fromUnixTime } from 'date-fns';
import actionCreator from "../../reducer/actionCreator";
import as from '../../reducer/actionStrings';
import './Users.css';
import 'firebase/firestore';
import getUnixTime from 'date-fns/getUnixTime';
import produce from "immer";

const UserList = () => {

  const dispatch = useDispatch();
  const userKey = useSelector(state => state.userKey);
  const sortedUserList = useSelector(state => produce(state.userList, draft => {
    draft = draft.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    return draft
  }) );

  const userSelectedClick = evt => {
    let key = evt.currentTarget.getAttribute('data-key');
    dispatch(actionCreator(as.USER_SELECTED, {userKey: key}));
  }

  const getExpireDate = user => {
    let label = isExpired(user.expiry) ? 'Expired: ' : 'Expires: ';
    return user.admin ? 'Admin' : label+format(fromUnixTime(user.expiry), 'MMM d, yyyy');
  }

  const isExpired = d => {
    let now = getUnixTime(new Date());
    return now > d;
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="text-center">User list</h4>
      </CardHeader>
      <CardBody>
        <ListGroup>
        {
          sortedUserList.map(user => (
            <ListGroupItem className={user.uid === userKey ? "active user-list-item" : "user-list-item"}
                           key={user.uid}
                           data-key={user.uid}
                           onClick={userSelectedClick}>
              <Row>
                <Col md={6}>
                  <h6>{user.name}</h6>
                </Col>
                <Col md={6}>
                  <h6 className={isExpired(user.expiry) ? 'expired' : ''}>{getExpireDate(user)}</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>{user.email}</h6>
                </Col>
              </Row>
            </ListGroupItem>
          ))
        }
        </ListGroup>
      </CardBody>
    </Card>
  )
}

export default UserList;
