const TRIAL_PERIOD = 365;
const TOKEN_EXPIRE_TIME = 120;
const USER_LIST_KEY = "userList";
const ELECTION_LIST_KEY = "electionList";
const VOTER_LIST_KEY = "voterList";
const CANDIDATE_LIST_KEY = "candidateListKey";
const SALT_LENGTH = 15;

module.exports = {
  TRIAL_PERIOD,
  USER_LIST_KEY,
  ELECTION_LIST_KEY,
  CANDIDATE_LIST_KEY,
  VOTER_LIST_KEY,
  TOKEN_EXPIRE_TIME,

  SALT_LENGTH
};
