import as from './actionStrings';
import produce from 'immer';

const reducer = (state, action) => {
  const newState = produce(state, draft => {

    switch (action.type) {
      case as.SET_ID_TOKEN:
        draft.idToken = action.payload.idToken;
        break;

      case as.SET_CLAIMS:
        draft.claims = action.payload.claims;
        break;

      case as.SET_USER_LIST:
        draft.userList = action.payload.list;
        break;

      case as.USER_SELECTED:
        draft.userKey = action.payload.userKey;
        break;

      case as.CLEAR_USER_SELECTED:
        draft.userKey = null;
        break;

      case as.SET_ELECTION_LIST:
        draft.electionList = action.payload.list;
        break;

      case as.ERROR:
        draft.errorCode = action.payload.errorCode;
        break;

      default:
        break;
    }

  });

  return newState;
}

export default reducer;
