import firebase from 'firebase/app';
import 'firebase/firestore';
import actionCreator from '../reducer/actionCreator';
import constants from "../utility/constants";
import apiCall from "../utility/apiCall";
import getUnixTime from "date-fns/getUnixTime";
import actionStrings from "../reducer/actionStrings";
import getFirestoreCollectionAndDispatch from "./getFirestoreCollectionAndDispatch";

/**
 * Login thunk to read the user information from Firestore and inject
 * it into the user claims in the idToken JWT.
 * @param authResult  Result from the signinSuccessWithAuthResult
 *                    callback from the StyledFirebaseUI component.
 *                    The updated idToken and injected claims are dispatched
 *                    to the reducer after they're retrieved from Firebase
 * @returns {function(*=, *): void}
 */
const loginThunk = authResult => dispatch => {
  const EXPIRY_DAYS = 10;
  let { user } = authResult;
  user.getIdTokenResult().then(idTokenResult => {
    let idToken = idTokenResult.token;

    // Retrieve the user information from Firestore...
    let userRef = firebase
      .firestore()
      .collection(constants.USER_LIST_KEY);
    userRef.doc(user.uid).get()
      .then(doc => {
        let admin = false;
        let expiry = getUnixTime(new Date()) + EXPIRY_DAYS * 24 * 60 * 60;

        // ... user information exists, grab the admin and expiry information
        if (doc.exists) {
          let userDoc = doc.data();
          admin = userDoc.admin;
          expiry = userDoc.expiry;

        // ... this is a new user; set up data payload and create the new user
        } else {
          let newUser = {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            admin,
            expiry
          };
          userRef.doc(user.uid)
            .set(newUser)
            .then(() => console.log('New user created'))
            .catch(() => console.log('Unable to create the user'));
        }

        // Issue an HTTP call to the server to inject custom claims into Firebase
        apiCall('setClaims', idToken, {admin, expiry}, dispatch)
          .then(data => {

            // Refresh the idToken, retrieve the claims, token, and dispatch a Redux update
            firebase?.auth().currentUser.getIdTokenResult(true)
              .then(idTokenResult => {
                // Trigger a claims refresh in useEffect above
                dispatch(actionCreator(actionStrings.SET_CLAIMS, {claims: idTokenResult.claims}));
                dispatch(actionCreator(actionStrings.SET_ID_TOKEN, {idToken: idTokenResult.token}));
                dispatch(getFirestoreCollectionAndDispatch(constants.ELECTION_LIST_KEY, actionStrings.SET_ELECTION_LIST));
                dispatch(getFirestoreCollectionAndDispatch(constants.USER_LIST_KEY, actionStrings.SET_USER_LIST));
              })
          })
          .catch(error => {
            dispatch(actionCreator(actionStrings.ERROR, {errorCode: error.code}))
            console.log(error);
          })
      })
      .catch(error => {
        dispatch(actionCreator(actionStrings.ERROR, {errorCode: error.code}))
        console.log(error);
      })
  })
}

export default loginThunk;
