import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import styles from './Login.css'
import Footer from "../Footer/Footer";
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import actionCreator from "../../reducer/actionCreator";
import actionStrings from "../../reducer/actionStrings";
import loginThunk from "../../thunks/loginThunk";
import './firebaseui-styling.global.css';
import './firebaseui-styling.local.css';
import './Login.css';

/**
 * Login component leveraging Firebase authentication and the react-firebaseui library
 *
 * Login flow:
 *   react-firebaseui prompts user for credentials: Google or email / password (phone number login is planned too)
 *   signInSuccessWithAuthResult is called with authResult object on successful login
 *   idToken is saved in idToken component state variable
 *   useEffect retrieves claims from idTokenResult
 *
 *   if user doesn't exist in Firestore
 *     create a new user in Firestore
 *   Retrieve user information
 *   setClaims API call with admin and expiry values to update Firebase claims of this user
 *   When claims are set, refresh idToken
 *   useEffect sees the change in idToken and retrieves new claims from idTokenResult
 *
 *   Claims available:
 *    Used:   admin, expiry, name, user_id (uid in auth object for Firestore rules)
 *    Unused: picture (URL), iss, aud, auth_time, sub,
 *            iat, exp, email_verified
 *
 * @returns {JSX.Element}
 */

const Login = () => {

  const [inProcess, setInProcess] = useState(false);
  const dispatch = useDispatch();

  const signInSuccessWithAuthResult = authResult => {
    setInProcess(true);
    dispatch(loginThunk(authResult));
    return false;
  }

  const signInFailure = error => {
    dispatch(actionCreator(actionStrings.ERROR, {errorCode: 'auth/signin-failure'}))
  }

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult,
      signInFailure
    },

    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase?.auth.EmailAuthProvider.PROVIDER_ID,
      firebase?.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],

    // Terms of service url.
    tosUrl: 'https://election-monkey.com',
    // Privacy policy url.
    privacyPolicyUrl: 'https://election-monkey.com'
  };

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>Election Monkey</h1>
        <h4>Administrator login</h4>
      </CardHeader>
      <CardBody>
        {
          firebase && !inProcess ?
            <StyledFirebaseAuth className={styles.firebaseUi}
                                uiConfig={uiConfig}
                                firebaseAuth={firebase.auth()} /> : null
        }
        {
          inProcess ?
            <h4 className="text-center">Logging in...</h4> : null
        }
      </CardBody>
      <Footer />
    </Card>
  )
}

export default Login;
