import React from "react";
import { CardHeader } from "reactstrap";

const Header = props => {
  return (
    <CardHeader className="text-center">
      <h1>Election Monkey</h1>
      <h4>User List</h4>
    </CardHeader>
  )
}

export default Header;
